






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}

.el-switch__core:after {
  background-color: #fff;
}

.dailyRemind-1 h2 {
  line-height: 3em;
}

.dailyRemind-1 {
  line-height: 2em;
}
